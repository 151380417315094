<template>

  <v-container id="loginPhone" class="h-100" fluid>

      <div class="inner-block">
<!--        <h1 class="subtitle is-2 text-center mt-3 mb-3">Восстановление<br>пароля</h1>-->
        <div class="columns">
          <div class="column">
            <v-form ref="form_log_phone" v-model="valid_verification" lazy-validation>
              <section>

                <h3 class="subtitle-1 mb-5">Вам поступит звонок.<br>Введите последние 4 цифры номера</h3>

                <b-field expanded class="mb-0">
                  <v-text-field
                    type="text"
                    label="Код"
                    v-model="code"
                    outlined
                    append-icon="mdi-phone"
                    :rules="codeRules"
                    v-mask="'####'"
                    inputmode="numeric"
                    :disabled="isCodeSend || timerExpire || disableCode"
                    required>
                  </v-text-field>
                </b-field>

                <p v-if="!timerExpire && !isCodeSend" class="help px-3">
                  <v-icon size="20px" style="margin-right: 15px;" left>mdi-clock</v-icon> {{ formattedTime }} - отправить код повторно
                </p>

                <p v-if="timerExpire" class="help px-3 text-danger">
                  <v-icon size="20px" style="margin-right: 15px;" left>mdi-clock</v-icon> Время истекло
                </p>

                <p v-if="attempts === 0" class="help px-3 text-danger">
                  <v-icon size="20px" style="margin-right: 15px;" left>mdi-close</v-icon> Попытки закончились
                </p>


                <b-field
                  expanded
                  class="mb-0">
                  <v-text-field
                    label="Новый пароль"
                    v-model="newPassword"
                    outlined
                    append-icon="mdi-phone"
                    :rules="passwordRules"

                    :type="showPass ? 'text' : 'password'"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"

                    :disabled="isCodeSend || timerExpire || disableCode"
                    required>
                  </v-text-field>
                </b-field>

              </section>
              <div class="text-center">
                <b-button
                  v-if="!timerExpire"
                  class="mt-0 w-100"
                  type="is-primary"
                  @click="sendCode"
                  :disabled="isCodeSend || disableCode"
                >
                  Подтвердить
                </b-button>

                <b-button
                  v-else
                  class="mt-0 w-100"
                  type="is-primary"
                  @click="reSendPhone"
                >
                  Повторить звонок
                </b-button>
              </div>

            </v-form>
          </div>
        </div>
      </div>
  </v-container>

</template>

<script>
export default {
  name: 'verificationCode',
  data() {
    return {
      code: '',
      newPassword: '',

      valid_verification: false,

      timeLifeDefault: 3*60,

      timeLeft: 0,
      startTime: null,
      timer: null,
      attempts: 2,
      maxAttempts: 2,
      timerExpire: false,
      isCodeSend: false,
      disableCode: false,

      showPass: false,
    };
  },
  created() {
    // запрос на код отправлен на предыдущей странице
  },
  mounted() {
    this.timeLeft = this.timeLifeDefault;
    this.startTime = Date.now();
    this.startTimer();
    this.disableCode = false;
  },
  computed: {
    phone() {
      return this.$store.getters.PHONE_FOR_FORGOT_PASSWORD;
    },
    codeRules() {
      return this.$store.getters.CODE_RULES;
    },
    passwordRules() {
      return this.$store.getters.PASSWORD_RULES;
    },
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${String(minutes)
        .padStart(2, '0')}:${String(seconds)
        .padStart(2, '0')}`;
    },
    regFrom() {
      return this.$store.getters.REGISTRATION_FROM;
    }
  },
  methods: {
    sendCode() {
      if (this.$refs.form_log_phone.validate()) {
        this.isCodeSend = true;

        let data_req = {
          "number": this.phone,
          "code": this.code,
          "new_password": this.newPassword
        }

        this.$store.dispatch('CONFIRM_PHONE_FOR_FORGOT', data_req).then(() => {
          console.log('Код подтвержден');
          this.$buefy.toast.open({
            message: 'Пароль изменён!',
            type: 'is-success'
          });

          this.$router.push('/activity-feed');

        }).catch((message) => {
          this.$buefy.toast.open({
            message: 'Код неверен',
            type: 'is-danger'
          });

          this.attempts = this.attempts - 1;
          this.isCodeSend = false;
          if (this.attempts === 0) {
            console.log('Попытки закончились');
            this.disableCode = true;
          }

        });
      } else {
        this.$buefy.toast.open({
          message: 'Введите корректный код',
          type: 'is-danger'
        });
      }
    },
    reSendPhone() {
      this.$store.dispatch('FORGOT_PASSWORD', this.$store.getters.PHONE_FOR_FORGOT_PASSWORD).then(() => {
        this.$buefy.toast.open({
          message: 'Код подтверждения отправлен',
          type: 'is-success'
        });

        this.attempts = this.maxAttempts;
        this.isCodeSend = false;
        this.timeLeft = this.timeLifeDefault;
        this.startTime = Date.now();
        this.startTimer();
        this.timerExpire = false;
        this.code = '';
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Ошибка! Попробуйте позже',
          type: 'is-danger'
        });
      });
    },

    startTimer() {
      if (this.timer) {
        cancelAnimationFrame(this.timer);
      }

      const tick = () => {
        const now = Date.now();
        const elapsed = Math.floor((now - this.startTime) / 1000);
        this.timeLeft = this.timeLifeDefault - elapsed;

        if (this.timeLeft > 0) {
          this.timer = requestAnimationFrame(tick);
        } else {
          // Время истекло
          this.timeLeft = 0;
          this.timerExpire = true;
          this.isCodeSend = false;
          this.disableCode = false;
          this.attempts = this.maxAttempts;
        }
      };

      this.timer = requestAnimationFrame(tick);
    }
  },
  beforeDestroy() {
    if (this.timer) {
      cancelAnimationFrame(this.timer);
    }
  }

};
</script>

<style>
#loginPhone .inner-block {
  position: absolute;

  /* Отступ сверху 20% */
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 12px;
}

#loginPhone .v-input .v-input__icon .v-icon.notranslate.material-icons {
  font-style: normal !important;
  margin-right: 10px !important;
}

#loginPhone .subtitle-1 {
  color: #4a4a4a;
  line-height: 1.3rem;
}

#loginPhone .help {
  font-size: 0.95rem;
  color: #4a4a4a;
}

#loginPhone .help.text-danger,
#loginPhone .help.text-danger .v-icon {
  color: #ff5252;
}
</style>
